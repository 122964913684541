import React, { lazy, Suspense } from 'react';
import LoginLogout from './components/elements/Web/LoginLogout';
import SuburbDropDown from './components/elements/Web/SuburbDropDown';
import ContactForm from './components/elements/Web/ContactForm';

const Base = lazy(() => import('./Base' /* webpackChunkName: "Base" */).catch(e => window.location.reload()));

class Wrapper extends React.Component {

    render() {
        let base = (document.getElementById('root') !== null ?
                    <Suspense fallback={this.props.renderLoader()}>
                        <Base
                            {...this.props}
                            element="root"
                        />
                    </Suspense> : null);
        let loginOut = (document.getElementById('header-login-button-holder') !== null ?
                    <Suspense fallback={this.props.renderLoader()}>
                        <LoginLogout
                            {...this.props}
                            element="header-login-button-holder"
                        />
                    </Suspense> : null);
        let mobileLoginOut = (document.getElementById('mobile-header-login-button-holder') !== null ?
                    <Suspense fallback={this.props.renderLoader()}>
                        <LoginLogout
                            {...this.props}
                            element="mobile-header-login-button-holder"
                        />
                    </Suspense> : null);
        let suburbDropDown = (document.getElementById('react-suburb-dropdown') !== null ?
                    <Suspense fallback={this.props.renderLoader()}>
                        <SuburbDropDown
                            {...this.props}
                            element="react-suburb-dropdown"
                        />
                    </Suspense> : null);
        let contactForm = (document.getElementById('react-contact-form') !== null ?
                    <Suspense fallback={this.props.renderLoader()}>
                        <ContactForm
                            {...this.props}
                            embed={true}
                        />
                    </Suspense> : null);

        return (
            <React.Fragment>
                {base}
                {loginOut}
                {mobileLoginOut}
                {suburbDropDown}
                {contactForm}
            </React.Fragment>
        );
    }
};

export default Wrapper;